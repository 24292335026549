import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { lastValueFrom } from 'rxjs';
import { events_endpoint, EventsService } from 'src/app/api/events/events.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { SocketClientService } from 'src/app/services/socket-client.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: false,
})
export class HeaderComponent implements OnInit {
  ep_events = events_endpoint.events;

  isShow: boolean = false;
  isShowPoint: boolean = false;

  id:any;
  user: any
  points: any = 0;

  eventData: any;

  navEndSubs: any;
  paramsSubs: any;
  eventSubs: any;
  authSubs: any;
  notifSubs: any;

  constructor(
    private auth: AuthService,
    private eventService: EventsService,
    private helper: HelperService,
    private router: Router,
    private route: ActivatedRoute,
    private storage: StorageMap,
    private socketClientService: SocketClientService
  ) {}

  ngOnInit() {
    this.paramsSubs = this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.eventSubs = this.eventService.eventData$.subscribe((res: any) => {
      const activeUrl2 = this.router.url;
      this.eventData = res;
      if (this.eventData) {
        this.id = this.eventData.id;
        this.isShowPoint = this.id && !activeUrl2.includes('home') && this.eventData.displayPoint;
      } else {
        lastValueFrom(this.storage.get('eventData')).then((res: any) => {
          if (res) {
            this.eventData = res;
            this.id = this.eventData.id;
            this.isShowPoint = this.id && !activeUrl2.includes('home') && this.eventData.displayPoint;
          }
        });
      }
    });

    this.navEndSubs = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.isShowPoint = this.id && !event.urlAfterRedirects.includes('home') && this.eventData.displayPoint;
        }, 500);
      }
    });

    this.auth.GET_UserData();

    this.authSubs = this.auth.users$.subscribe((res: any) => {
      this.user = res;
      if (this.user && this.id && this.user.roleName === 'USER') {
        this.getById();
      } else {
      }
    });

    this.notifSubs = this.socketClientService.notif_presence$.subscribe((res: any) => {
      if (res) {
        const msg = JSON.parse(res.body);
        if (msg.id === this.auth.users.id) {
          this.helper.showAlert(
            '',
            msg.message ?? 'Check in berhasil',
            msg.message?.toLowerCase().includes('check in berhasil') ? 'success' : 'info',
            null,
            msg?.addedPoint ? `+ ${msg?.addedPoint} poin` : null
          ).then(() => {
            if (this.id) {
              this.getById();
            }
          });
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.navEndSubs) {
      this.navEndSubs.unsubscribe();
    }
    if (this.paramsSubs) {
      this.paramsSubs.unsubscribe();
    }
    if (this.eventSubs) {
      this.eventSubs.unsubscribe();
    }
    if (this.authSubs) {
      this.authSubs.unsubscribe();
    }
    if (this.notifSubs) {
      this.notifSubs.unsubscribe();
    }
  }

  toggle() {
    this.isShow = !this.isShow;
  }

  logout() {
    this.helper
      .showAlert('', 'Apakah kamu yakin ingin logout?', 'confirm')
      .then(
        (res) => {
          if (res) {
            this.auth.logoutAdfs().then((res) => {
              if (res.output_schema) {
                // this.helper.showSuccessAlert('Success', 'Logout berhasil.');
                setTimeout(() => {
                  const externalUrl = res.output_schema.logout_url;
                  if (externalUrl) {
                    this.auth.logout();
                    window.location.href = externalUrl;
                  }
                }, 500);
              }
            });
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  getById() {
    const epPoints = this.ep_events + '/' + this.id + '/user-point';
    this.eventService.getAll2('', epPoints).then(
      (res) => {
        this.points = res ?? 0;
      },
      (err) => {
        // console.log(err);
        // this.helper.showAlert('Error', err?.message ?? err ?? 'Gagal mendapatkan data point user.', 'error');
      },
    );
  }

  profile() {
    this.router.navigateByUrl('/u/profile');
  }
}
