<div class="header">
  <img src="assets/media/logo-horizontal.svg" alt="" class="h-[18px]" />
  <div class="profile-wrapper" (click)="op.toggle($event)">
    @if (isShowPoint) {
      <div class="mx-2 text-xs font-bold text-white">{{ points }} Poin</div>
    }

    @if (!user?.profileUrl) {
      <!-- no photo -->
      <div class="avatar h-8 w-8 bg-[#CCD4E9]">
        <div class="text-sm font-bold">{{ user?.initial }}</div>
      </div>
    } @else {
      <!-- with photo -->
      <img
        [src]="user?.profileUrl"
        alt=""
        class="img-profile bg-[#2E3885]"
        loading="lazy"
      />
    }

    <p-overlayPanel #op>
      <div class="profile-dropdown">
        <div class="flex flex-col gap-4">
          <div class="flex items-center gap-1" (click)="profile(); op.toggle($event)">
            <img src="assets/icon/icon-person.svg" alt="" class="w-5" />
            <div class="text-primary">
              <div class="text-dark text-xs">Profil Saya</div>
            </div>
          </div>
          <div class="flex items-center gap-1" (click)="logout(); op.toggle($event)">
            <img src="assets/icon/icon-logout-blue.svg" alt="" class="w-5" />
            <div class="text-primary">
              <div class="text-dark text-xs">Logout</div>
            </div>
          </div>
        </div>
      </div>
    </p-overlayPanel>
  </div>
</div>
