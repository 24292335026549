import { Injectable, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AuthService } from '../core/services/auth.service';
// import { MessageService } from 'primeng/api';
// import { MessageBubble_m } from '../api/inbox/inbox.model';
import { HelperService } from './helper.service';
// var sockjs = require('sockjs');

import * as Stomp from 'stompjs';
// var stompjs = require('stompjs');
// import { Client, IMessage, Stomp } from '@stomp/stompjs';

import SockJS from 'sockjs-client'
import { HeaderComponent } from '../shared/widget/header/header.component';
// var sockjs = require('sockjs-client');

@Injectable({
  providedIn: 'root',
})
export class SocketClientService {
  socketClient: any = null;
  url = 'https://api-miebca2024.unictive.net/ws';

  subs!: Subscription;
  subs2!: Subscription;
  subs3!: Subscription;
  subs4!: Subscription;

  public notif_liveQ = new BehaviorSubject<any>(null);
  notif_liveQ$ = this.notif_liveQ.asObservable();

  public notif_presence = new BehaviorSubject<any>(null);
  notif_presence$ = this.notif_presence.asObservable();

  idActive: any;

  isConnecting: boolean = false; // Status untuk mencegah pemanggilan berulang

  @ViewChild(HeaderComponent) headers!: HeaderComponent;

  constructor(
    private auth: AuthService,
    // private messageService: MessageService,
    private helper: HelperService
  ) {}

  private configureSocketClient(client: any) {
    client.debug = null;

    client.heartbeat.outgoing = 20000; // Send heartbeat every 20 seconds
    client.heartbeat.incoming = 20000; // Expect heartbeat every 20 seconds

    client.reconnect_delay = 5000; // Reconnect after 5 seconds if connection is lost

    client.onclose = () => {
      console.log('WebSocket connection closed. Attempting to reconnect...');
      setTimeout(() => {
        this.initSocket();
        this.openConnection();
      }, client.reconnect_delay);
    };

    return client;
  }

  private initSocket() {
    if (this.socketClient) {
      return;
    }

    console.log('Initialize WebSocket Connection');

    let ws = new SockJS(this.url);

    this.socketClient = Stomp.over(ws);
    this.socketClient = this.configureSocketClient(this.socketClient);
  }

  async openConnection(eventId?: any, scheduleId?: any) {
    return new Promise<void>((resolve, reject) => {
      if (this.socketClient && this.socketClient.connected) {
        console.log("STOMP already connected.");
        if (eventId) {
          this.runListenerChat(eventId);
          if (scheduleId) {
            this.runListenMessage(eventId, scheduleId);
          }
        }
        resolve();
        return;
      }

      if (this.isConnecting) {
        console.log("Already attempting to connect...");
        return;
      }

      this.isConnecting = true;
      this.initSocket();

      this.socketClient.connect(
        {},
        (frame: any) => {
          console.log("STOMP connection established.");
          this.isConnecting = false;

          if (eventId) {
            this.runListenerChat(eventId);
            if (scheduleId) {
              this.runListenMessage(eventId, scheduleId);
            }
          }
          resolve();
        },
        (error: any) => {
          console.error("STOMP connection error:", error);
          this.isConnecting = false;
          reject(error);
        }
      );
    });
  }

  async runListenerChat(eventId: any) {
    if (this.auth.users && this.auth.users.id) {
      if (!this.socketClient || !this.socketClient.connected) {
        await this.openConnection();
      }
      this.runListenMessage3(eventId, this.auth.users.id);
    }
  }

  sendMessage(message: any) {
    this.socketClient.send(
      '/app/chat',
      {},
      JSON.stringify({
        fromUser: 'abc',
        toUser: 'efg',
        message: message,
      })
    );
  }

  showMessage(msg: any) {
    // this.messageService.add({
    //   key: 'confirm',
    //   sticky: true,
    //   severity: 'success',
    //   data: msg,
    // });
  }

  disconnent() {
    this.socketClient.disconnect();
  }


  // MARK: TEST DEVELOPMENT
  runListenMessage(eventId: any, scheduleId: any) {
    if (!!this.subs2) {
      this.subs2?.unsubscribe();
    }
    this.subs2 = this.listenMessages(eventId, scheduleId).subscribe((message: any) => {
      this.notif_liveQ.next(message);
    });
  }

  listenMessages(eventId: any, scheduleId: any): Observable<any> {
    return new Observable((subscribe) => {
      if (!this.socketClient || !this.socketClient.connected) {
        console.error("WebSocket is not connected yet!");
        return;
      }

      var channel = `/events/${eventId}/schedule/${scheduleId}`;
      this.subs = this.socketClient.subscribe(channel, (message: any) => {
        subscribe.next(message);
      });
    });
  }

  // MARK: Connection Prensence
  runListenMessage3(eventId: any, usersId: any) {
    if (this.subs3) {
      this.subs3.unsubscribe();
    }
    this.subs3 = this.listenMessages3(eventId, usersId).subscribe((message: any) => {
      this.notif_presence.next(message);
    });
  }

  listenMessages3(eventId: any, usersId: any): Observable<any> {
    return new Observable((subscribe) => {
      if (!this.socketClient || !this.socketClient.connected) {
        console.error("WebSocket is not connected yet!");
        return;
      }

      var channel = `/events/${eventId}/users/${usersId}/point`;
      this.subs4 = this.socketClient.subscribe(channel, (message: any) => {
        subscribe.next(message);
      });
    });
  }
}
